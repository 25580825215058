<template>
  <div id="card-date-from" class="card mb-3">
    <div class="card-header"><i class="fas fa-search"></i> ค้นหา</div>
    <div class="card-body">

      <div class="d-flex">
        <div class="form-check">
          <input id="range-1" class="form-check-input" type="radio" name="range" value="this-week" v-model="dateRange">
          <label class="form-check-label" for="range-1">
            สัปดาห์นี้ (จันทร์ - อาทิตย์)
          </label>
        </div>

        <div class="form-check ml-3">
          <input id="range-2" class="form-check-input" type="radio" name="range" value="last-week" v-model="dateRange">
          <label class="form-check-label" for="range-2">
            สัปดาห์ที่แล้ว (จันทร์ - อาทิตย์)
          </label>
        </div>
      </div>

      <div class="d-flex mt-2">
        <div class="form-check">
          <input id="range-3" class="form-check-input" type="radio" name="range" value="month" v-model="dateRange">
          <label class="form-check-label label-input" for="range-3">
            เดือน
          </label>
          <flat-pickr
            :config="monthPickerConfig"
            v-model="selectedMonth"
            class="form-control form-control-sm ml-2"
          />
        </div>
      </div>

      <div class="d-flex mt-2">
        <div class="form-check">
          <input id="range-4" class="form-check-input" type="radio" name="range" value="date" v-model="dateRange">
          <label class="form-check-label label-input" for="range-4">
            วันที่
          </label>
          <flat-pickr
            :config="datePickerConfig"
            v-model="selectedDate"
            class="form-control form-control-sm ml-2"
          />
        </div>
      </div>

      <div class="d-flex mt-2">
        <div class="form-check">
          <input id="range-5" class="form-check-input" type="radio" name="range" value="week" v-model="dateRange">
          <label class="form-check-label label-input" for="range-5">
            สัปดาห์
          </label>
          <flat-pickr
            :config="weekPickerConfig"
            v-model="selectedWeek"
            class="form-control form-control-sm ml-2"
          />
        </div>
      </div>

      <div v-if="branches.length" class="d-flex align-items-center mt-3">
        <div>
          <label style="width: 80px; text-align: center; font-weight: bold; margin: 0;">ทีม</label>
        </div>
        <div>
          <b-form-select v-model="selectedBranch" :options="branchOptions"></b-form-select>
        </div>
      </div>

    </div>
    <div class="card-footer py-2 px-3">
      <button class="btn btn-primary btn-sm" @click="confirmDate">ตกลง</button>
    </div>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import weekSelect from 'flatpickr/dist/plugins/weekSelect/weekSelect.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import {Thai as ThaiLocale} from 'flatpickr/dist/l10n/th.js'

import moment from '@/helpers/moment'
import Swal from 'sweetalert2'

export default {
  name: 'CardDateFrom',
  components: {
    flatPickr
  },
  props: {
    branches: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  data() {
    return {
      selectedBranch: null,
      dateRange: 'this-week',

      monthPickerConfig: {
        locale: ThaiLocale,
        plugins: [
          new monthSelectPlugin({
            dateFormat: "Y-m"
          })
        ],
        onChange: ()=>{
          this.dateRange = 'month'
        }
      },
      selectedMonth: moment().format("YYYY-MM"),

      weekPickerConfig: {
        locale: ThaiLocale,
        weekNumbers: true,
        plugins: [
          new weekSelect({dateFormat: "w"})
        ],
        onChange: [()=>{
          this.dateRange = 'week'
        }]
      },
      selectedWeek: moment().startOf('w').format("YYYY-MM-DD"),

      datePickerConfig: {
        mode: "range",
        locale: ThaiLocale,
        dateFormat: "Y-m-d",
        onChange: ()=>{
          this.dateRange = 'date'
        },
        maxDate: moment().format("YYYY-MM-DD")
      },
      selectedDate: moment().format("YYYY-MM-DD"),

    }
  },
  computed: {
    dataDate() {
      return {
        'this-week': ()=>{
          const start = moment().startOf('week').format("YYYY-MM-DD")
          const end = moment().endOf('week').format("YYYY-MM-DD")
          return { start, end }
        },
        'last-week': ()=>{
          const start = moment().add(-1, 'w').startOf('week').format("YYYY-MM-DD")
          const end = moment().add(-1, 'w').endOf('week').format("YYYY-MM-DD")
          return { start, end }
        },
        'month': ()=>{
          const start = moment(this.selectedMonth).startOf('month').format("YYYY-MM-DD")
          const end = moment(this.selectedMonth).endOf('month').format("YYYY-MM-DD")
          return { start, end }
        },
        'date': ()=>{
          const input = this.selectedDate.split(" ถึง ")
          return { start: input.at(0), end: input.at(-1) }
        },
        'week': ()=>{
          const start = moment(this.selectedWeek).startOf('week').format("YYYY-MM-DD")
          const end = moment(this.selectedWeek).endOf('week').format("YYYY-MM-DD")
          return { start, end }
        }
      }[this.dateRange]()
    },
    branchOptions() {
      let options = [
        { value: null, text: 'ทั้งหมด' }
      ]

      for(const item of this.branches) {
        options.push({
          value: item._id,
          text: item.name
        })
      }

      return options
    }
  },
  methods: {
    confirmDate() {
      const diff = moment(this.dataDate.end).diff(this.dataDate.start, 'd') + 1
      if(diff > 60) {
        return Swal.fire({
          title: 'ผิดพลาด!',
          text: 'จำนวนวันเกิน 60 วัน',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      this.$emit('selected', {
        date: this.dataDate,
        branch: this.selectedBranch
      })
    },
  },
  mounted() {
    this.$emit('selected', {
      date: this.dataDate,
      branch: this.selectedBranch
    })
  }
}
</script>
<style lang="scss" scoped>
#card-date-from {
  .form-check {
    display: flex;
    align-items: center;

    input[type=radio] {
      width: 20px;
      height: 20px;
      margin-top: 0;
    }

    label {
      margin-left: 8px;
    }

    .label-input {
      width: 60px;
    }
  }
}
</style>
